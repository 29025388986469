import { ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { switchMap, map, catchError } from 'rxjs/operators'

import { SIGNOUT, signoutSuccess, signoutError } from '../actions/user'

export default (action$, _, { firebase }) =>
  action$.pipe(
    ofType(SIGNOUT),
    switchMap(
      () =>
        from(firebase.auth.signOut())
          .pipe(
            map(signoutSuccess),
            catchError(err => of(signoutError(err)))
          )
    )
  )
