import { REGISTER, REGISTER_SUCCESS, REGISTER_ERROR } from '../actions/register'

const INITIAL_STATE = {
  loading: false,
  error: null,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REGISTER: {
      return {
        ...state,
        error: null,
        loading: true,
      }
    }
    case REGISTER_SUCCESS: {
      return {
        ...state,
        error: null,
        loading: false,
      }
    }
    case REGISTER_ERROR: {
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    }
    default: {
      return state
    }
  }
}
