export const PREFERENCES_SUCCESS = '@@gini.web/PREFERENCES_SUCCESS'
export const PREFERENCES_ERROR = '@@gini.web/PREFERENCES_ERROR'

export const getPrefsSuccess = preferences => ({
  type: PREFERENCES_SUCCESS,
  payload: preferences,
})

export const getPrefsError = error => ({
  type: PREFERENCES_ERROR,
  error,
})
