export const ADMIN_GOT_CREDENTIALS = '@@gini.web/ADMIN_GOT_CREDENTIALS'
export const ADMIN_GOT_CREDENTIALS_ERROR = '@@gini.web/ADMIN_GOT_CREDENTIALS_ERROR'
export const ADMIN_GOT_USER_RISKS = '@@gini.web/ADMIN_GOT_USER_RISKS'
export const ADMIN_GET_USER_RISKS = '@@gini.web/ADMIN_GET_USER_RISKS'
export const ADMIN_GET_PRODUCT = '@@gini.web/ADMIN_GET_PRODUCT'
export const ADMIN_GOT_PRODUCT = '@@gini.web/ADMIN_GOT_PRODUCT'
export const ADMIN_GOT_PLATFORM_ERROR = '@@gini.web/ADMIN_GOT_PLATFORM_ERROR'
export const ADMIN_GET_USER_CHECK = '@@gini.web/ADMIN_GET_USER_CHECK'
export const ADMIN_GOT_USER_CHECK = '@@gini.web/ADMIN_GOT_USER_CHECK'
export const ADMIN_GOT_USER_CHECK_ERROR = '@@gini.web/ADMIN_GOT_USER_CHECK_ERROR'

export const gotAdminCredentials = payload => ({
  type: ADMIN_GOT_CREDENTIALS,
  payload
})

export const gotAdminCredentialsError = payload => ({
  type: ADMIN_GOT_CREDENTIALS_ERROR,
  payload
})

export const gotAdminUserRisks = payload => ({
  type: ADMIN_GOT_USER_RISKS,
  payload
})

export const getAdminUserRisks = payload => ({
  type: ADMIN_GET_USER_RISKS,
  payload
})

export const getPlatformProduct = payload => ({
  type: ADMIN_GET_PRODUCT,
  payload
})

export const gotPlatformProduct = payload => ({
  type: ADMIN_GOT_PRODUCT, 
  payload
})

export const gotPlatformError = payload => ({
  type: ADMIN_GOT_PLATFORM_ERROR,
  payload
})

export const getUserCheck = uid => ({
  type: ADMIN_GET_USER_CHECK,
  payload: uid,
})

export const gotUserCheck = payload => ({
  type: ADMIN_GOT_USER_CHECK, 
  payload
})

export const gotUserCheckError = payload => ({
  type: ADMIN_GOT_USER_CHECK_ERROR,
  payload
})