import { ofType } from 'redux-observable'
import { tap, ignoreElements } from 'rxjs/operators'

import { SIGNOUT_SUCCESS } from '../actions/user'

export default (action$, state$) =>
  action$.pipe(
    ofType(SIGNOUT_SUCCESS),
    tap(() => window.location.assign('https://ginihealth.com')),
    ignoreElements()
  )
