import { ofType } from 'redux-observable'
import { throwError, of } from 'rxjs'
import { switchMap, map, catchError } from 'rxjs/operators'

import { gotRecipeScore, RECIPE_SCORE_GET, getRecipeFailed } from '../actions/recipes'

export default (action$, _, { firebase }) =>
  action$.pipe(
    ofType(RECIPE_SCORE_GET),
    switchMap(async payload => {
      const getData = await firebase.functions
        .httpsCallable('getRecipeScore')({
          recipeId: payload.recipeId
        })
        .catch(error => {
          throwError(error)
        })
      return { id: payload.recipeId, score: getData.data }
    }),
    catchError(error => of(getRecipeFailed(error))),
    map(result => gotRecipeScore(result))
  )
