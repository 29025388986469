import { ofType, combineEpics } from 'redux-observable'
import { from, of } from 'rxjs'
import {
  switchMap,
  map,
  catchError
} from 'rxjs/operators'
import { SIGNIN, signinSuccess, signinError } from '../actions/user'

export const signInEpic = (action$, _, { firebase }) =>
  action$.pipe(
    ofType(SIGNIN),
    switchMap(({ payload: { email, password, page, id } }) =>
      from(firebase.auth.signInWithEmailAndPassword(email, password))
      .pipe(
        map(auth => signinSuccess({ email, password, page, id, ...auth })),
        catchError(err => of(signinError(err)))
      )
    )
  )

export default combineEpics(signInEpic)
