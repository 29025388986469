import { ofType } from 'redux-observable'
import { of, Observable } from 'rxjs'
import { switchMap, map, catchError } from 'rxjs/operators'

import { SIGNIN_SUCCESS } from '../actions/user'
import { getTargetsError, getTargetsSuccess } from '../actions/targets'

export default (action$, state$, { firebase }) =>
action$.pipe(
  ofType(SIGNIN_SUCCESS),
  switchMap(
    ({ payload: { user: { uid } } }) =>
      Observable.create(observer => {
        try {
          const unsubscribe = firebase.fs
            .collection('user_targets')
            .doc(uid)
            .onSnapshot(
              snap => snap.exists && observer.next(snap.data()),
              err => observer.error(err),
            )
          return () => unsubscribe()
        } catch (err) {
          observer.error(err)
        }
      })
        .pipe(
          map(getTargetsSuccess),
          catchError(err => of(getTargetsError(err.message)))
        )
  )
)
