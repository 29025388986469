import { ofType } from 'redux-observable'
import { throwError, of } from 'rxjs'

import { switchMap, map, catchError, tap, ignoreElements } from 'rxjs/operators'

import { SIGNIN_SUCCESS } from '../actions/user'

import {
  SUBSCRIPTION_CANCELLED_ERROR,
  SUBSCRIPTION_GET_ERROR,
  SUBSCRIPTION_CANCEL,
  cancelledSubscription,
  gotSubscription,
  getSubscriptionError,
  cancelledSubscriptionError
} from '../actions/subscriptions'

import { track } from '../util/analytics'

export const getSubscriptions = (action$, _, { firebase }) =>
  action$.pipe(
    ofType(SIGNIN_SUCCESS),
    switchMap(async ({ payload }) => {
      try {
        const uid = payload.user.uid
        const getUserPermissions = await firebase.db
          .ref(`/user_permissions_v2/${uid}`)
          .once('value')

        const activePermissions = Object.keys(getUserPermissions.val()).filter(
          current => getUserPermissions.val()[current] === true
        )

        const getCancellationStatus = await firebase.db
          .ref(`/cancellations/${uid}`)
          .once('value')

        const activeSubs = await Promise.all(
          activePermissions.map(ref =>
            firebase.fs
              .collection('users')
              .doc(uid)
              .collection('purchases')
              .doc(ref)
              .get()
          )
        )

        const subs = activeSubs.map(doc => {
          return doc.data()
        })

        return {
          activeSubs: subs,
          cancellationStatus: getCancellationStatus.val()
        }
      } catch (error) {
        throwError(error)
      }
    }),
    catchError(error => of(getSubscriptionError(error))),
    map(result => gotSubscription(result))
  )

export const cancelSubscriptionEpic = (action$, _, { firebase }) =>
  action$.pipe(
    ofType(SUBSCRIPTION_CANCEL),
    switchMap(async ({ payload }) => {
      track('Subscription Cancellation', {
        uid: payload.uid,
        sku: payload.sku
      })
      await firebase.db
        .ref(`/cancellations/${payload.uid}`)
        .update({
          [payload.sku]: false
        })
        .catch(error => throwError(error))
      return { [payload.sku]: false }
    }),
    catchError(error => of(cancelledSubscriptionError(error))),
    map(result => cancelledSubscription(result))
  )

export const subscriptionErrorsEpic = (action$, state$) =>
  action$.pipe(
    ofType(SUBSCRIPTION_CANCELLED_ERROR, SUBSCRIPTION_GET_ERROR),
    tap(({ payload }) => {
      track('Subscription Error', { payload })
    }),
    ignoreElements()
  )
