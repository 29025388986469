import { SIGNOUT_SUCCESS, PROFILE_LOAD_SUCCESS, SIGNIN_SUCCESS, SIGNUP_SUCCESS } from '../actions/user'

const INITIAL_STATE = {
  uid: '',
  email: '',
  first_name: '',
  last_name: '',
  sex: '',
  dob: '',
  connected: null,
  registered: false,
  onboarded: false,
  information: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SIGNOUT_SUCCESS: {
      return INITIAL_STATE
    }
    case SIGNUP_SUCCESS:
    case SIGNIN_SUCCESS: {
      return {
        ...state,
        uid: action.payload.user.uid,
        email: action.payload.user.email,
      }
    }
    case PROFILE_LOAD_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default: {
      return state
    }
  }
}