import { ofType, combineEpics } from 'redux-observable'
import { throwError, of } from 'rxjs'
import { switchMap, map, catchError } from 'rxjs/operators'

import { DATA_ENTRY_ADD, DATA_ENTRY_GET, addDataEntrySuccess, addDataEntryError, getDataEntrySuccess } from '../actions/data-entry'

const addDataEpic = (action$, _, { firebase }) =>
  action$.pipe(
    ofType(DATA_ENTRY_ADD),
    switchMap(async payload => {
      try {
        const item = payload.payload
        await firebase.fs
          .collection('additional_food_data')
          .doc(item.nutritionixId.toString())
          .set({
            nutritionixId: item.nutritionixId.toString(),
            name: item.name,
            diets: item.diets,
            allergies: item.allergies,
            glycemic: item.glycemic,
            nutrition: item.nutrition,
            tags: item.tags.split(','),
            foodGroup: item.foodGroup,
          }, { merge: true })
        return { completed: true }
      } catch (err) {
        throwError(err)
      }
    }),
    map(result => addDataEntrySuccess(result)),
    catchError(error => of(addDataEntryError(error)))
  )

const getDataEpic = (action$, _, { firebase }) =>
  action$.pipe(
    ofType(DATA_ENTRY_GET),
    switchMap(async payload => {
      try {
        const result = await firebase.fs
          .collection('additional_food_data')
          .doc(payload.payload.toString())
          .get()
        return result.data()
      } catch (err) {
        throwError(err)
      }
    }),
    map(result => getDataEntrySuccess(result)),
    catchError(error => of(addDataEntryError(error)))
  )

export default combineEpics(addDataEpic, getDataEpic)