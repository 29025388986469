import { ofType } from 'redux-observable'
import { throwError, of, from } from 'rxjs'
import { switchMap, map, catchError } from 'rxjs/operators'
import { SIGNIN_SUCCESS } from '../actions/user'

import { 
  ADMIN_GET_PRODUCT,
  ADMIN_GET_USER_RISKS,
  ADMIN_GET_USER_CHECK,
  gotAdminCredentials,
  gotAdminCredentialsError,
  gotAdminUserRisks,
  gotPlatformProduct,
  gotPlatformError,
  gotUserCheck,
  gotUserCheckError,
} from '../actions/admin'

export const getAdminCredentialsEpic = (action$, _, { firebase }) =>
  action$.pipe(
    ofType(SIGNIN_SUCCESS),
    switchMap(async ({ payload }) => {
      try {
        const uid = payload.user.uid
        const getUserPermissions = await firebase.db
          .ref(`/admins/${uid}`)
          .once('value')
        return getUserPermissions.val()
      } catch (error) {
        throwError(error)
      }
    }),
    catchError(error => of(gotAdminCredentialsError(error))),
    map(result => gotAdminCredentials(result))
  )


export const getRisksEpic = (action$, _, { firebase }) =>
  action$.pipe(
    ofType(ADMIN_GET_USER_RISKS),
    switchMap(async ({ payload }) => {
      try {
        const getRisks = firebase.functions.httpsCallable('getRiskPlatformAdmin')
        const risksResults = await getRisks({uid: payload})
        return risksResults
      } catch (error) {
        throwError(error)
      }
    }),
    catchError(error => of(gotAdminCredentialsError(error))),
    map(result => gotAdminUserRisks(result))
  )

export const getProductEpic = (action$, _, { firebase }) =>
  action$.pipe(
    ofType(ADMIN_GET_PRODUCT),
    switchMap(async () => {
      const product = await firebase.fs
        .collection('platform')
        .doc('vitamin_lab')
        .collection('products')
        .doc('customised_supplements1')
        .get()
      return product.data()
    }),
    map(result => gotPlatformProduct(result)),
    catchError(error => of(gotPlatformError(error))),
  )

export const getUserCheckEpic = (action$, _, { firebase }) =>
  action$.pipe(
    ofType(ADMIN_GET_USER_CHECK),
    switchMap(({ payload }) =>
      from(
        firebase.functions.httpsCallable('userCheck')({ uid: payload })
      ).pipe(
        map(res => gotUserCheck(res.data)),
        catchError(err => of(gotUserCheckError({ error: err.message }))),
      )
    ),
  )
