import { ofType } from 'redux-observable'
import {  tap, ignoreElements } from 'rxjs/operators'
import { navigate } from 'gatsby'
import {combineEpics} from 'redux-observable'

import { NAVIGATE_RESET_PASSWORD, RESET_PASSWORD_SUCCESS } from '../actions/user'

const navReset = (action$, state$) => 
  action$.pipe(
    ofType(NAVIGATE_RESET_PASSWORD),
    tap(() => navigate('/reset')),
    ignoreElements()
  )

const navSignin = (action$, state$) => 
  action$.pipe(
    ofType(RESET_PASSWORD_SUCCESS),
    tap(() => navigate('/reset-password-complete')),
    ignoreElements()
  )

  export default combineEpics(
    navReset,
    navSignin
  )