import { RECIPE_SCORE_RECEIVED } from '../actions/recipes'

const INITIAL_STATE = {
  recipeScore: {
    id: null,
    score: null
  }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECIPE_SCORE_RECEIVED: {
      return {
        recipeScore: action.payload
      }
    }

    default: {
      return state
    }
  }
}

export const recipeScore = state => state.recipeScore
