import { ofType } from 'redux-observable'
import { Observable } from 'rxjs'
import { switchMap, map } from 'rxjs/operators'

import { DNA_UPLOAD_URL, updateUploadProgress } from '../actions/dna'

export default (action$, state$) =>
  action$.pipe(
    ofType(DNA_UPLOAD_URL),
    switchMap(({ payload: { url } }) =>
      Observable.create(o => {
        const file = state$.value.dna.file
        let xhr = new window.XMLHttpRequest()
        xhr.open('PUT', url)
        xhr.onload = evt => {
          o.next(100)
          o.complete()
        }
        xhr.upload.onprogress = evt => o.next(evt.loaded / evt.total * 100)
        xhr.send(file)
      }).pipe(
        map(updateUploadProgress)
      )
    )
  )
