export const RECIPE_SCORE_GET = '@@gini.web/RECIPE_SCORE_GET'
export const RECIPE_SCORE_RECEIVED = '@@gini.web/RECIPE_SCORE_RECEIVED'
export const RECIPE_TRACK_DOWNLOAD = '@@gini.web/RECIPE_TRACK_DOWNLOAD'
export const RECIPE_GET_FAILED = '@@gini.web/RECIPE_GET_FAILED'

export const getRecipeScore = id => ({
  type: RECIPE_SCORE_GET,
  recipeId: id
})

export const trackDownload = (user, recipeId, platform) => ({
  type: RECIPE_TRACK_DOWNLOAD,
  payload: {
    user,
    recipeId,
    platform
  }
})

export const gotRecipeScore = result => ({
  type: RECIPE_SCORE_RECEIVED,
  payload: result
})

export const getRecipeFailed = error => ({
  type: RECIPE_GET_FAILED,
  payload: error
})
