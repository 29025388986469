import { ADMIN_GOT_CREDENTIALS, ADMIN_GOT_USER_RISKS, ADMIN_GOT_PRODUCT, ADMIN_GOT_USER_CHECK, ADMIN_GOT_USER_CHECK_ERROR } from '../actions/admin'

const INITIAL_STATE = {
  isAdmin: null,
  risks: [],
  userCheck: {},
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADMIN_GOT_CREDENTIALS: {
      return {
        ...state, isAdmin: action.payload
      }
    }
    case ADMIN_GOT_USER_RISKS: {
      return {
        ...state, risks: action.payload
      }
    }
    case ADMIN_GOT_PRODUCT: {
      return {
        ...state, product: action.payload
      }
    }
    case ADMIN_GOT_USER_CHECK_ERROR:
    case ADMIN_GOT_USER_CHECK: {
      return {
        ...state, userCheck: action.payload,
      }
    }
    default: {
      return state
    }
  }
}
