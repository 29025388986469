import { ofType } from 'redux-observable'
import { of, Observable } from 'rxjs'
import { switchMap, map, catchError } from 'rxjs/operators'

import { SIGNIN_SUCCESS } from '../actions/user'
import { getNutritionError, getNutritionSuccess } from '../actions/nutrition'

export default (action$, state$, { firebase }) =>
action$.pipe(
  ofType(SIGNIN_SUCCESS),
  switchMap(
    ({ payload: { user: { uid } } }) =>
      Observable.create(observer => {
        try {
          firebase.db
            .ref(`/user_health/${uid}`)
            .on(
              'value',
              snap => snap && snap.val() && observer.next(snap.val()),
            )
          return () => firebase.db.ref(`/user_health/${uid}`).off()
        } catch (err) {
          observer.error(err)
        }
      })
        .pipe(
          map(getNutritionSuccess),
          catchError(err => of(getNutritionError(err.message)))
        )
  )
)
