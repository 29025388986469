import { ofType } from 'redux-observable'
import { throttleTime, map, catchError, switchMap } from 'rxjs/operators'
import { of, from } from 'rxjs'
import { REGISTER, registerKitSuccess, registerKitError } from '../actions/register'

export default (action$, state$, { firebase }) =>
  action$.pipe(
    ofType(REGISTER),
    throttleTime(500),
    switchMap(({ payload }) =>
      from(
        firebase.functions.httpsCallable('registerGiniKit')({ code: payload })
      ).pipe(
        map(registerKitSuccess),
        catchError(err => of(registerKitError(err)))
      )
    )
  )
