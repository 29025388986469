import { DATA_GET_ENTRY_SUCCESS } from '../actions/data-entry'

const INITIAL_STATE = {
  dataEntry: {
    allergies: {},
    diets: {},
    glycemic: 0,
    name: '',
    nutrition: {
      choline: 0,
      iodine: 0,
      probiotic: 0
    },
    nutritionixId: '',
    tags: [],
  }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DATA_GET_ENTRY_SUCCESS: {
      if(action.payload === undefined) {
        return state
      }
      const food = action.payload
      return {
        dataEntry: {
          allergies: food.allergies,
          diets: food.diets,
          glycemic: food.glycemic,
          name: food.name,
          nutrition: food.nutrition,
          nutritionixId: food.nutritionixId,
          tags: food.tags,
          foodGroup: food.foodGroup,
        }
      }
    }

    default: {
      return state
    }
  }
}

export const dataEntryData = state => state.dataEntry
