import { ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { switchMap, map, catchError } from 'rxjs/operators'

import { SIGNUP, signupSuccess, signupError } from '../actions/user'

export default (action$, _, { firebase }) =>
  action$.pipe(
    ofType(SIGNUP),
    switchMap(
      ({ payload: { email, password } }) =>
        from(firebase.auth.createUserWithEmailAndPassword(email, password))
          .pipe(
            map(signupSuccess),
            catchError(err => of(signupError(err)))
          )
    )
  )
