import { SIGNOUT_SUCCESS } from '../actions/user'
import { NUTRITION_SUCCESS, NUTRITION_ERROR } from '../actions/nutrition'

const INITIAL_STATE = {
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NUTRITION_ERROR:
    case SIGNOUT_SUCCESS: {
      return INITIAL_STATE
    }
    case NUTRITION_SUCCESS: {
      return action.payload
    }
    default: {
      return state
    }
  }
}
