import { combineEpics, ofType } from 'redux-observable'
import { tap, ignoreElements, filter, take } from 'rxjs/operators'
import { identify, track } from '../util/analytics'
import { RECIPE_TRACK_DOWNLOAD, RECIPE_GET_FAILED } from '../actions/recipes'
import { SIGNIN_SUCCESS, SIGNUP_SUCCESS } from '../actions/user'
import { DNA_UPLOAD_DROP, DNA_UPLOAD_URL, DNA_UPLOAD_PROGRESS } from '../actions/dna'

const loginEpic = (action$, state$) =>
  action$.pipe(
    ofType(SIGNIN_SUCCESS),
    tap(({ payload }) => {
      const { email, uid } = payload.user
      identify(uid, { email, uid })
    }),
    ignoreElements()
  )


const downloadTrackEpic = (action$, state$) =>
  action$.pipe(
    ofType(RECIPE_TRACK_DOWNLOAD),
    tap(({ payload }) => {
      const { uid , email } = payload.user
      const data = {
        firstName: payload.user.first_name,
        lastName: payload.user.last_name,
        sex: payload.user.sex,
        dob: payload.user.dob,
        platform: payload.platform,
        recipeId: payload.recipeId
      }
      track('download clicked', { email, uid, ...data })
    }),
    ignoreElements()
  )

const getRecipeScoreFailedEpic = (action$, state$) =>
  action$.pipe(
    ofType(RECIPE_GET_FAILED),
    tap(({ payload }) => {
      track('Failed Recipe', { payload })
    }),
    ignoreElements()
  )

const signupEpic = (action$, state$) =>
  action$.pipe(
    ofType(SIGNUP_SUCCESS),
    tap(({ payload }) => {
      const { uid, email } = payload.user
      const data = {
        firstName: state$.value.signup.firstName,
        lastName: state$.value.signup.lastName,
        first_name: state$.value.signup.firstName,
        last_name: state$.value.signup.lastName,
        sex: state$.value.signup.sex,
        dob: state$.value.signup.dob,
        information: true,
        ...( 
          !!state$.value.signup.phoneNumber && 
          { 
            number: `${state$.value.signup.dialCode}${state$.value.signup.phoneNumber}` 
          }
        ),
      }
      if (window && window.gtag) {
        window.gtag('event', 'conversion', {
          'send_to': 'AW-757510680/tkyoCJzLmv0BEJjkmukC',
          email,
          uid,
        })
      }
      track('Account SignUp', { email, uid, ...data })
      identify(uid, { email, uid, ...data })
    }),
    ignoreElements()
  )

const dnaTestedEpic = (action$, state$) =>
  action$.pipe(
    ofType(SIGNUP_SUCCESS),
    tap(() => {
      const dnaTested = state$.value.signup.dnaTested
      track('DNA Tested Company', { dnaTested })
    }),
    ignoreElements()
  )

const dnaTestedTopEpic = (action$, state$) =>
  action$.pipe(
    ofType(SIGNUP_SUCCESS),
    filter(() =>
      ['23andme', 'ancestry', 'myheritage'].includes(state$.value.signup.dnaTested)
    ),
    tap(() => {
      const dnaTested = state$.value.signup.dnaTested
      track('DNA Tested Main Companies', { dnaTested })
    }),
    ignoreElements()
  )

const dropEpic = (action$, state$) =>
  action$.pipe(
    ofType(DNA_UPLOAD_DROP),
    tap(({ payload }) => {
      const { email, uid } = state$.value.user
      const { type } = payload.file
      track('Upload File Selected', { type, uid, email })
    }),
    ignoreElements()
  )

const startUploadEpic = (action$, state$) =>
  action$.pipe(
    ofType(DNA_UPLOAD_URL),
    tap(() => {
      const { email, uid } = state$.value.user
      const { type } = state$.value.dna.file
      track('Upload File Started', { type, uid, email })
    }),
    ignoreElements()
  )

const finishUploadEpic = (action$, state$) =>
  action$.pipe(
    ofType(DNA_UPLOAD_PROGRESS),
    filter(({ payload }) => payload.progress === 100),
    take(1),
    tap(() => {
      const { email, uid } = state$.value.user
      const { type } = state$.value.dna.file
      track('Upload File Done', { type, uid, email })
    }),
    ignoreElements()
  )


export default combineEpics(
  loginEpic,
  signupEpic,
  dropEpic,
  startUploadEpic,
  finishUploadEpic,
  dnaTestedEpic,
  dnaTestedTopEpic,
  downloadTrackEpic,
  getRecipeScoreFailedEpic
)