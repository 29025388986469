export const TARGETS_SUCCESS = '@@gini.web/TARGETS_SUCCESS'
export const TARGETS_ERROR = '@@gini.web/TARGETS_ERROR'

export const getTargetsSuccess = targets => ({
  type: TARGETS_SUCCESS,
  payload: targets,
})

export const getTargetsError = error => ({
  type: TARGETS_ERROR,
  error,
})
