export const DNA_UPLOAD_DROP = '@@gini.web/DNA_UPLOAD_DROP'
export const DNA_UPLOAD_URL = '@@gini.web/DNA_UPLOAD_URL'
export const DNA_UPLOAD_PROGRESS = '@@gini.web/DNA_UPLOAD_PROGRESS'
export const DNA_UPLOAD_ERROR = '@@gini.web/DNA_UPLOAD_ERROR'

export const handleFileDrop = file => ({
  type: DNA_UPLOAD_DROP,
  payload: {
    file
  }
})

export const generateUploadURL = url => ({
  type: DNA_UPLOAD_URL,
  payload: {
    url
  }
})

export const updateUploadProgress = progress => ({
  type: DNA_UPLOAD_PROGRESS,
  payload: {
    progress
  }
})

export const uploadFileError = error => ({
  type: DNA_UPLOAD_ERROR,
  error
})
