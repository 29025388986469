import { ofType } from 'redux-observable'
import { of, bindCallback, Subject } from 'rxjs'
import { switchMap, map, catchError } from 'rxjs/operators'

import { SIGNIN_SUCCESS, loadProfileSuccess, loadProfileError, SIGNUP_SUCCESS } from '../actions/user'

export default (action$, state$, { firebase }) =>
  action$.pipe(
    ofType(SIGNIN_SUCCESS, SIGNUP_SUCCESS),
    switchMap(
      ({ payload: { user: { uid } } }) => {
        const listener = new Subject()
        firebase.usersRef
          .child(uid)
          .child('app_data')
          .on('value', snap => !!snap.val() && listener.next(snap.val()))
        return listener.asObservable()
          .pipe(
            map(loadProfileSuccess),
            catchError(err => of(loadProfileError(err)))
          )
      }
    )
  )
