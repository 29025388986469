import { ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { switchMap, map, catchError } from 'rxjs/operators'
import { format } from 'date-fns'

import {
  SIGNUP_SUCCESS,
  saveProfileSuccess,
  saveProfileError,
} from '../actions/user'
import { track } from '../util/analytics'

const generateData = (state) => ({
  first_name: state.value.signup.firstName,
  last_name: state.value.signup.lastName,
  sex: state.value.signup.sex,
  dob: format(state.value.signup.dob, 'YYYY-MM-DD'),
  connected: false,
  information: true,
  onboarded: false,
  tested: state.value.signup.dnaTested,
  ...(!!state.value.signup.phoneNumber && {
    number: `${state.value.signup.dialCode}${state.value.signup.phoneNumber}`,
  }),
})

export default (action$, state$, { firebase }) =>
  action$.pipe(
    ofType(SIGNUP_SUCCESS),
    switchMap(
      ({
        payload: {
          user: { uid },
        },
      }) => {
        track('Referral: CODE SUBMIT SUCCESS')
        return from(
          firebase.usersRef
            .child(uid)
            .child('app_data')
            .update(generateData(state$))
        ).pipe(
          map(saveProfileSuccess),
          catchError((err) => of(saveProfileError(err)))
        )
      }
    )
  )
