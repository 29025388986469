import { combineReducers } from 'redux'

import admin from './admin'
import dataEntry from './data-entry'
import dna from './dna'
import nutrition from './nutrition'
import preferences from './preferences'
import recipes from './recipes'
import register from './register'
import signup from './signup'
import subscription from './subscription'
import targets from './targets'
import user from './user'

export default combineReducers({
  admin,
  dataEntry,
  dna,
  nutrition,
  preferences,
  recipes,
  register,
  signup,
  subscription,
  targets,
  user,
})
