import { REFERRALCODE_CHANGE, EMAIL_CHANGE, PASSWORD_CHANGE, FIRST_NAME_CHANGE, LAST_NAME_CHANGE, SEX_CHANGE, DOB_CHANGE, SIGNUP, SIGNUP_SUCCESS, SIGNUP_ERROR, PROFILE_SAVE_SUCCESS, PROFILE_SAVE_ERROR, SIGNIN, SIGNIN_SUCCESS, SIGNIN_ERROR, SIGNOUT_SUCCESS, DNA_TESTED_CHANGE, DIAL_CODE_CHANGE, PHONE_NUMBER_CHANGE } from '../actions/user'

const INITIAL_STATE = {
  loading: false,
  error: '',
  uid: '',
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  dialCode:'+1',
  phoneNumber:'',
  sex: '',
  dob: '',
  dnaTested: '',
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SIGNOUT_SUCCESS: {
      return INITIAL_STATE
    }
    case REFERRALCODE_CHANGE: {
      return {
        ...state,
        referralCode: action.payload.referralCode
      }
    }
    case EMAIL_CHANGE: {
      return {
        ...state,
        email: action.payload.email
      }
    }
    case PASSWORD_CHANGE: {
      return {
        ...state,
        password: action.payload.password
      }
    }
    case FIRST_NAME_CHANGE: {
      return {
        ...state,
        firstName: action.payload.firstName
      }
    }
    case LAST_NAME_CHANGE: {
      return {
        ...state,
        lastName: action.payload.lastName
      }
    }
    case DIAL_CODE_CHANGE: {
      return {
        ...state,
        dialCode: action.payload.dialCode
      }
    }
    case PHONE_NUMBER_CHANGE: {
      return {
        ...state,
        phoneNumber: action.payload.phoneNumber
      }
    }
    case DNA_TESTED_CHANGE: {
      return {
        ...state,
        dnaTested: action.payload.dnaTested
      }
    }
    case SEX_CHANGE: {
      return {
        ...state,
        sex: action.payload.sex
      }
    }
    case DOB_CHANGE: {
      return {
        ...state,
        dob: action.payload.dob
      }
    }
    case SIGNIN_SUCCESS:
    case SIGNUP_SUCCESS: {
      return {
        ...state,
        uid: action.payload.user.uid
      }
    }
    case SIGNIN:
    case SIGNUP: {
      return {
        ...state,
        loading: true
      }
    }
    case SIGNIN_ERROR: {
      return {
        ...state,
        loading: false,
        error: 'You have entered an invalid email or password.'
      }
    }
    case SIGNUP_ERROR: {
      const getErrorMsg = () => {
        switch (action.error.code) {
          case 'auth/invalid-email':
            return 'You have entered an invalid email'
          case 'auth/email-already-in-use':
          case 'auth/weak-password':
            return action.error.message
          default:
            return 'Sorry, there was a problem creating your account.'
        }
      }
      return {
        ...state,
        loading: false,
        error: getErrorMsg(),
      }
    }
    case PROFILE_SAVE_ERROR:
    case PROFILE_SAVE_SUCCESS: {
      return {
        ...state,
        loading: false
      }
    }
    default: {
      return state
    }
  }
}
