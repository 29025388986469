import { ofType } from 'redux-observable'
import { bindCallback } from 'rxjs'
import { switchMap, map, filter } from 'rxjs/operators'

import { BOOTSTRAP } from '../actions/session'
import { signinSuccess } from '../actions/user'

export default (action$, _, { firebase }) =>
  action$.pipe(
    ofType(BOOTSTRAP),
    switchMap(() =>
      bindCallback(firebase.auth.onAuthStateChanged)
        .bind(firebase.auth)()
        .pipe(
          filter(user => user),
          map(user => signinSuccess({ user }))
        )

    )
  )
