export const identify = (id, traits, options, cb) => {
  if (window && window.analytics) {
    window.analytics.identify(id, traits, options, cb)
  }
}

export const track = (id, props, options) => {
  if (window && window.analytics) {
    window.analytics.track(id, props, options)
  }
}

export const page = (id, props, options) => {
  if (window && window.analytics) {
    window.analytics.page(id, props, options)
  }
}
