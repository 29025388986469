export const NUTRITION_SUCCESS = '@@gini.web/NUTRITION_SUCCESS'
export const NUTRITION_ERROR = '@@gini.web/NUTRITION_ERROR'

export const getNutritionSuccess = nutrition => ({
  type: NUTRITION_SUCCESS,
  payload: nutrition,
})

export const getNutritionError = error => ({
  type: NUTRITION_ERROR,
  error,
})
