import { ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { switchMap, mapTo, catchError } from 'rxjs/operators'

import { RESET_PASSWORD, RESET_PASSWORD_SUCCESS } from '../actions/user'

export default (action$, _, { firebase }) =>
action$.pipe(
  ofType(RESET_PASSWORD),
  switchMap(
    ({ payload }) =>
      from(firebase.auth.sendPasswordResetEmail(payload.email))
        .pipe(
          mapTo({type: RESET_PASSWORD_SUCCESS}),
          catchError(err => of(console.error(err)))
        )
  )
)

