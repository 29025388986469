export const EMAIL_CHANGE = '@@gini.web/EMAIL_CHANGE'
export const PASSWORD_CHANGE = '@@gini.web/PASSWORD_CHANGE'
export const FIRST_NAME_CHANGE = '@@gini.web/FIRST_NAME_CHANGE'
export const LAST_NAME_CHANGE = '@@gini.web/LAST_NAME_CHANGE'
export const DIAL_CODE_CHANGE = '@@gini.web/DIAL_CODE_CHANGE'
export const PHONE_NUMBER_CHANGE = '@@gini.web/PHONE_NUMBER_CHANGE'
export const DNA_TESTED_CHANGE = '@@gini.web/DNA_TESTED_CHANGE'
export const SEX_CHANGE = '@@gini.web/SEX_CHANGE'
export const DOB_CHANGE = '@@gini.web/DOB_CHANGE'
export const SIGNUP = '@@gini.web/SIGNUP'
export const SIGNUP_SUCCESS = '@@gini.web/SIGNUP_SUCCESS'
export const SIGNUP_ERROR = '@@gini.web/SIGNUP_ERROR'
export const PROFILE_SAVE_SUCCESS = '@@gini.web/PROFILE_SAVE_SUCCESS'
export const PROFILE_SAVE_ERROR = '@@gini.web/PROFILE_SAVE_ERROR'
export const PROFILE_LOAD_SUCCESS = '@@gini.web/PROFILE_LOAD_SUCCESS'
export const PROFILE_LOAD_ERROR = '@@gini.web/PROFILE_LOAD_ERROR'
export const SIGNOUT = '@@gini.web/SIGNOUT'
export const SIGNOUT_SUCCESS = '@@gini.web/SIGNOUT_SUCCESS'
export const SIGNOUT_ERROR = '@@gini.web/SIGNOUT_ERROR'
export const SIGNIN = '@@gini.web/SIGNIN'
export const SIGNIN_SUCCESS = '@@gini.web/SIGNIN_SUCCESS'
export const SIGNIN_ERROR = '@@gini.web/SIGNIN_ERROR'
export const RESET_PASSWORD_SUCCESS = '@@gini.web/RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD = '@@gini.web/RESET_PASSWORD'
export const NAVIGATE_RESET_PASSWORD = '@@gini.web/NAVIGATE_RESET_PASSWORD'
export const NAVIGATE_TO_ACCOUNT = '@@gini.web/NAVIGATE_TO_ACCOUNT'
export const REFERRALCODE_CHANGE = '@@gini.web/REFERRALCODE_CHANGE'

export const changeReferralCode = referralCode => ({
  type: REFERRALCODE_CHANGE,
  payload: {
    referralCode
  }
})

export const changeEmail = email => ({
  type: EMAIL_CHANGE,
  payload: {
    email
  }
})

export const changePassword = password => ({
  type: PASSWORD_CHANGE,
  payload: {
    password
  }
})

export const changeFirstName = firstName => ({
  type: FIRST_NAME_CHANGE,
  payload: {
    firstName
  }
})

export const changeLastName = lastName => ({
  type: LAST_NAME_CHANGE,
  payload: {
    lastName
  }
})

export const changeDialCode = dialCode => ({
  type: DIAL_CODE_CHANGE,
  payload: {
    dialCode
  }
})

export const changePhoneNumber = phoneNumber => ({
  type: PHONE_NUMBER_CHANGE,
  payload: {
    phoneNumber
  }
})

export const changeDnaTested = dnaTested => ({
  type: DNA_TESTED_CHANGE,
  payload: {
    dnaTested
  }
})

export const changeSex = sex => ({
  type: SEX_CHANGE,
  payload: {
    sex
  }
})

export const changeDOB = dob => ({
  type: DOB_CHANGE,
  payload: {
    dob
  }
})

export const signup = (email, password) => ({
  type: SIGNUP,
  payload: {
    email,
    password
  }
})

export const signupSuccess = userData => ({
  type: SIGNUP_SUCCESS,
  payload: {
    ...userData
  }
})

export const signupError = error => ({
  type: SIGNUP_ERROR,
  error
})

export const saveProfileSuccess = () => ({
  type: PROFILE_SAVE_SUCCESS
})

export const saveProfileError = error => ({
  type: PROFILE_SAVE_ERROR,
  error
})

export const loadProfileSuccess = profile => ({
  type: PROFILE_LOAD_SUCCESS,
  payload: profile
})

export const loadProfileError = error => ({
  type: PROFILE_LOAD_ERROR,
  error
})

export const signin = (email, password, page, id) => ({
  type: SIGNIN,
  payload: {
    email,
    password,
    page,
    id
  }
})

export const signinSuccess = userData => ({
  type: SIGNIN_SUCCESS,
  payload: {
    ...userData
  }
})

export const resetPasswordSuccess = userData => ({
  type: RESET_PASSWORD_SUCCESS
})

export const resetPassword = email => ({
  type: RESET_PASSWORD,
  payload: {
    email
  }
})

export const navigateResetPassword = () => ({
  type: NAVIGATE_RESET_PASSWORD
})

export const signinError = error => ({
  type: SIGNIN_ERROR,
  error
})

export const signout = () => ({
  type: SIGNOUT
})

export const signoutSuccess = () => ({
  type: SIGNOUT_SUCCESS
})

export const signoutError = error => ({
  type: SIGNOUT_ERROR,
  error
})
