import { createStore, applyMiddleware, compose } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import { ajax } from 'rxjs/ajax'

import rootReducer from '../reducers'
import rootEpic from '../epics'
import firebase from '../util/firebase'

const composeEnhancers = (typeof window !== 'undefined' &&
                          window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
                         compose
const epicMiddleware = createEpicMiddleware({
  dependencies: {
    getJSON: ajax.getJSON,
    firebase
  }
})

export default () => {
  const store = createStore(
    rootReducer,
    composeEnhancers(
      applyMiddleware(epicMiddleware)
    )
  )
  epicMiddleware.run(rootEpic)

  return store
}
