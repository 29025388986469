// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-appinstall-js": () => import("./../../../src/pages/appinstall.js" /* webpackChunkName: "component---src-pages-appinstall-js" */),
  "component---src-pages-complete-js": () => import("./../../../src/pages/complete.js" /* webpackChunkName: "component---src-pages-complete-js" */),
  "component---src-pages-data-entry-js": () => import("./../../../src/pages/data-entry.js" /* webpackChunkName: "component---src-pages-data-entry-js" */),
  "component---src-pages-fitbit-js": () => import("./../../../src/pages/fitbit.js" /* webpackChunkName: "component---src-pages-fitbit-js" */),
  "component---src-pages-import-js": () => import("./../../../src/pages/import.js" /* webpackChunkName: "component---src-pages-import-js" */),
  "component---src-pages-lens-js": () => import("./../../../src/pages/lens.js" /* webpackChunkName: "component---src-pages-lens-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-reset-js": () => import("./../../../src/pages/reset.js" /* webpackChunkName: "component---src-pages-reset-js" */),
  "component---src-pages-reset-password-complete-js": () => import("./../../../src/pages/reset-password-complete.js" /* webpackChunkName: "component---src-pages-reset-password-complete-js" */),
  "component---src-pages-signin-js": () => import("./../../../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-subscriptions-js": () => import("./../../../src/pages/subscriptions.js" /* webpackChunkName: "component---src-pages-subscriptions-js" */)
}

