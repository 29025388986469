import { DNA_UPLOAD_DROP, DNA_UPLOAD_URL, DNA_UPLOAD_PROGRESS, DNA_UPLOAD_ERROR } from '../actions/dna'

const INITIAL_STATE = {
  file: null,
  uploadURL: '',
  uploadPct: 0,
  error: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DNA_UPLOAD_DROP: {
      return {
        ...state,
        error: null,
        file: action.payload.file
      }
    }
    case DNA_UPLOAD_URL: {
      return {
        ...state,
        uploadURL: action.payload.url
      }
    }
    case DNA_UPLOAD_PROGRESS: {
      return {
        ...state,
        uploadPct: action.payload.progress
      }
    }
    case DNA_UPLOAD_ERROR: {
      return {
        ...state,
        file: null,
        error: action.error
      }
    }
    default: {
      return state
    }
  }
}
