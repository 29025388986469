export const SUBSCRIPTION_GOT = '@@gini.web/SUBSCRIPTION_GOT'
export const SUBSCRIPTION_CANCEL = '@@gini.web/SUBSCRIPTION_CANCEL'
export const SUBSCRIPTION_CANCELLED = '@@gini.web/SUBSCRIPTION_CANCELLED'
export const SUBSCRIPTION_GET_ERROR = '@@gini.web/SUBSCRIPTION_GET_ERROR'
export const SUBSCRIPTION_CANCELLED_ERROR = '@@gini.web/SUBSCRIPTION_CANCELLED_ERROR'
export const cancelSubscription = (uid, sku) => ({
  type: SUBSCRIPTION_CANCEL,
  payload: {
    sku,
    uid
  }
})

export const cancelledSubscription = result => ({
  type: SUBSCRIPTION_CANCELLED,
  payload: result
})

export const gotSubscription = result => ({
  type: SUBSCRIPTION_GOT,
  payload: result
})

export const cancelledSubscriptionError = error => ({
  type: SUBSCRIPTION_CANCELLED_ERROR,
  error
})

export const getSubscriptionError = error => ({
  type: SUBSCRIPTION_GET_ERROR,
  error
})
