import { ofType } from 'redux-observable'
import { filter, tap, ignoreElements } from 'rxjs/operators'
import { navigate } from 'gatsby'

import { PROFILE_SAVE_SUCCESS, SIGNIN_SUCCESS } from '../actions/user'

export default (action$, state$) =>
  action$.pipe(
    ofType(PROFILE_SAVE_SUCCESS, SIGNIN_SUCCESS),
    filter(() => /signin|signup/.test(window.location.pathname)),
    tap(({ payload }) => getNav(payload)),
    ignoreElements()
  )

const getNav = (payload) => {
  if (payload) {
    return navigate(!!payload.page ? `/${payload.page}/${payload.id}` : '/account')
  }
  return navigate('/account')
}