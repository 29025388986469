import {
  SUBSCRIPTION_GOT,
  SUBSCRIPTION_CANCELLED
} from '../actions/subscriptions'

const INITIAL_STATE = {
  subscriptions: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SUBSCRIPTION_GOT: {
      if(action.payload === undefined){
        return null
      }
      return action.payload
    }
    case SUBSCRIPTION_CANCELLED: {
      const [sku, value] = Object.entries(action.payload)[0]
      const newState = {
        ...state,
        cancellationStatus: { ...state.cancellationStatus, [sku]: value }
      }
      return newState
    }
    default: {
      return state
    }
  }
}
