export const REGISTER = '@@gini.web/REGISTER'
export const REGISTER_SUCCESS = '@@gini.web/REGISTER_SUCCESS'
export const REGISTER_ERROR = '@@gini.web/REGISTER_ERROR'

export const registerKit = code => ({
  type: REGISTER,
  payload: code,
})

export const registerKitSuccess = () => ({
  type: REGISTER_SUCCESS,
})

export const registerKitError = error => ({
  type: REGISTER_ERROR,
  error,
})
