import { ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { switchMap, map, catchError } from 'rxjs/operators'

import {
  DNA_UPLOAD_DROP,
  generateUploadURL,
  uploadFileError,
} from '../actions/dna'

const validate = file => {
  if (file.type.match(/zip|text|csv/) === null) {
    return 'Selected can only be a zip, csv, or text file.'
  }
  if (file.size < 3000000) {
    return 'Selected file is too small.'
  }
  if (
    (file.type.match(/txt/) !== null && file.size > 60000000)
    || (file.type.match(/zip|csv/) !== null && file.size > 30000000)
  ) {
    return 'Selected file is too large.'
  }
  return null
}

export default (action$, _, { firebase }) =>
  action$.pipe(
    ofType(DNA_UPLOAD_DROP),
    switchMap(
      ({ payload: { file } }) =>
        validate(file) !== null
          ? of(uploadFileError(validate(file)))
          : from(
              firebase.functions.httpsCallable('getDNAFileUploadUrl')({
                filename: file.name,
                contentType: file.type
              })
            ).pipe(
              map(res => generateUploadURL(res.data.url)),
              catchError(err => of(uploadFileError(err.message))),
            )
    ),
    catchError(err => of(uploadFileError(err.message))),
  )
