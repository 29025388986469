export const DATA_ENTRY_ADD = '@@gini.web/DATA_ENTRY_ADD'
export const DATA_ENTRY_ADD_SUCCESS = '@@gini.web/DATA_ENTRY_ADD_SUCESSS'
export const DATA_ENTRY_ADD_ERROR = '@@gini.web/DATA_ENTRY_ADD_ERROR'
export const DATA_ENTRY_GET = '@@gini.web/DATA_ENTRY_GET'
export const DATA_GET_ENTRY_SUCCESS = '@@gini.web/DATA_GET_ENTRY_SUCCESS'

export const getDataEntry = payload => ({
  type: DATA_ENTRY_GET,
  payload,
})
export const addDataEntry = payload => ({
  type: DATA_ENTRY_ADD,
  payload
})

export const addDataEntrySuccess = payload => ({
  type: DATA_ENTRY_ADD_SUCCESS,
  payload
})

export const addDataEntryError = payload => ({
  type: DATA_ENTRY_ADD_ERROR,
  payload
})

export const getDataEntrySuccess = payload => ({
  type: DATA_GET_ENTRY_SUCCESS,
  payload
})