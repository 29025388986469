module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gini","short_name":"gini","start_url":"/","background_color":"#ffffff","theme_color":"#2b0064","display":"minimal-ui","icon":"src/images/gini-logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4cff80c718d2b3e04794b2381c487f95"},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"9ZuaISqZrZDPf3zQDOsbOuQBkzkI7iR2","devKey":"9ZuaISqZrZDPf3zQDOsbOuQBkzkI7iR2","trackPage":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-intercom-spa/gatsby-browser.js'),
      options: {"plugins":[],"app_id":"vaux57rf","include_in_development":true,"delay_timeout":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
