import firebase from 'firebase/app'

const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyDWNmtaAl1mx0f6yMWjsn4kBNQ93FN3L5Y',
  authDomain: 'gini-v0.firebaseapp.com',
  databaseURL: 'https://gini-v0.firebaseio.com',
  projectId: 'gini-v0'
}

let db, auth, functions, usersRef, fs

if (typeof window !== 'undefined') {
  require('firebase/auth')
  require('firebase/database')
  require('firebase/functions')
  require('firebase/firestore')

  if (!firebase.apps.length) {
    firebase.initializeApp(FIREBASE_CONFIG)
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  }

  db = firebase.database()
  fs = firebase.firestore()
  auth = firebase.auth()
  functions = firebase.functions()
  usersRef = db.ref('users')
}

export default {
  db,
  fs,
  auth,
  functions,
  usersRef
}
