import { combineEpics } from 'redux-observable'

import analytics from './analytics'
import resumeSession from './resume-session'
import authenticate from './authenticate'
import signout from './signout'
import createUser from './create-user'
import saveUserProfile from './save-user-profile'
import getUserProfile from './get-user-profile'
import getUserTargets from './get-user-targets'
import getUserNutrition from './get-user-nutrition'
import getUserPreferences from './get-user-preferences'
import navigateToAccount from './navigate-to-account'
import navigateToWelcome from './navigate-to-welcome'
import registerKit from './register-kit'
import getUploadURL from './get-upload-url'
import uploadFile from './upload-file'
import resetPassword from './reset-password'
import navigateResetPassword from './navigate-to-password-reset'
import addDataEntry from './data-entry'
import Recipes from './recipes'
import * as subscriptionEpics from './subscription'
import * as adminEpics from './admin'

export default combineEpics(
  analytics,
  resumeSession,
  authenticate,
  signout,
  createUser,
  saveUserProfile,
  getUserProfile,
  getUserTargets,
  getUserNutrition,
  getUserPreferences,
  registerKit,
  navigateToAccount,
  navigateToWelcome,
  getUploadURL,
  uploadFile,
  resetPassword,
  navigateResetPassword,
  Recipes,
  addDataEntry,
  ...Object.values(subscriptionEpics),
  ...Object.values(adminEpics),
)
