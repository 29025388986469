import React from 'react'
import { Provider } from 'react-redux'

import createStore from './src/store'
import { BOOTSTRAP } from './src/actions/session'

const store = createStore()

if (typeof window !== 'undefined') {
  store.dispatch({ type: BOOTSTRAP })
}

export default ({ element }) => <Provider store={store}>{element}</Provider>
